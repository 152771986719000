import React from 'react';

import DocsLayout from '../../components/ui/DocsLayout';

const Content = ({ data }) => (
  <DocsLayout>
    <h1>Content Management</h1>
    <p>
      The new SpotCast website uses a concept called headless CMS for content
      management. Basically, the CMS and front end are no longer connected to
      each other, freeing the developer and designer from constraints the come
      with traditional platforms like WordPress&nbsp;or Joomla.
    </p>
    <p>
      Our chosen cms is <a href="https://www.datocms.com/">Dato CMS</a>. We
      build content models in dato&nbsp;(e.g a groups page) fill in the form and
      content is then supplied to an API&nbsp;which then goes on to be used by
      the front end.
    </p>
    <p>
      A bonus of this system&nbsp;is that our content can be
      reused&nbsp;anywhere. For example, we may choose to make a native app one
      day. The app can just use the content from Dato. This saves us money
      because we only need to manage one piece of content.
    </p>
  </DocsLayout>
);

export default Content;
